import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';

import DropdownRegion from 'gatsby-theme-dettol/src/components/DropdownRegion';
import { IFooterTopRegionsComponent } from 'gatsby-theme-dettol/src/components/Footer/FooterTop/FooterTopRegions/models';

const FooterTopRegions: FC<IFooterTopRegionsComponent> = ({ regionDropDown, titleRegions }) => {
  const { section, selectedCountryCode } = regionDropDown;

  return (
    <Col className="dt-footer__top-col" sm={6} lg={4} xl={3}>
      <strong className="dt-footer__top-title">{titleRegions || 'Regions'}</strong>

      <DropdownRegion data={section} selectedCountryCode={selectedCountryCode} />
    </Col>
  );
};

export default FooterTopRegions;
