import React, { FC } from 'react';
import { Link } from 'gatsby';
import Col from 'react-bootstrap/Col';

import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import { NavWraper } from 'gatsby-theme-dettol/src/components/Footer/FooterTop/FooterTopCategriesCols/FooterTopCategriesCols';
import { IFooterTopCategoriesComponent } from 'gatsby-theme-dettol/src/components/Footer/FooterTop/FooterTopCategriesCols/models';

const FooterTopCategriesCols: FC<IFooterTopCategoriesComponent> = ({ footerNavigation }) => {
  return (
    <>
      {footerNavigation?.compositionContainer.map(({ properties }) => (
        <Col className="dt-footer__top-col" sm={6} lg={4} xl={3} key={properties.name}>
          <strong className="dt-footer__top-title">
            <Link to={extractUrlFromMultiPicker(properties.groupLink)}>{properties.name}</Link>
          </strong>

          <NavWraper links={properties.links} />
        </Col>
      ))}
    </>
  );
};

export default FooterTopCategriesCols;
